img {
    width: 100%;
    height: auto;
}

// lazyload
.lazyload {
	opacity: 0;
}

.lazyloading {
	opacity: 1;
	transition: opacity 300ms;
	background: $white url('../img/loading.gif') no-repeat center;
}

.blur-up {
	-webkit-filter: blur(5px);
	filter: blur(15px);
	transform: scale(1.2);
	transition: filter 400ms, -webkit-filter 400ms, transform 400ms;

	&.lazyloaded {
		transform: scale(1);
		-webkit-filter: blur(0);
		filter: blur(0);
	}
}

.imagecontainer {
	position: relative;
	overflow: hidden;

	&::before {
		display: block;
		width: 100%;
		content: '';
		padding-bottom: 100%;
		height: 0;
	}
}

.imagecontainer-img {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: 400ms transform;

	&.contain {
		object-fit: contain;
		font-family: 'object-fit: contain';
	}

	&.cover {
		object-fit: cover;
		font-family: 'object-fit: cover';
	}
}

// Blue Up plugin styles
.ls-blur-up-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	filter: blur(10px);
	opacity: 1;
	transition: opacity 1000ms, filter 1500ms;

	&.ls-inview {
		&.ls-original-loaded {
			opacity: 0;
			filter: blur(5px);
		}
	}

}