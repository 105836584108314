a {
    color: inherit;
    text-decoration: none;

    &:link {
        color: inherit;
        text-decoration: none;
    }
}

p {
    a {
        font-weight: 700;
        position: relative;
        overflow: hidden;
        transition: ease .5s;
        padding: .2em;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
            background: $black;
            transition: ease .3s;
            z-index: -1;
        }

        &:hover {
            color: $white;
            transition: ease .5s;
            background: $black;
            &::before {
                width: 100%;
                transition: ease .3s;
            }
        }
    }
}