.homepage {
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        opacity: 0;
        animation: fadeIn 1s forwards .5s;
    }

    .logo {
        opacity: 0;
        animation: fadeIn 1s forwards .3s;
    }

    .textarea {
        width: 380px;
        max-width: 80%;
        z-index: 1;
        opacity: 0;
        animation: fadeInUp 1s forwards 1s;
    }

    .button-wrap {
        display: none;
    }

    .page-name {
        opacity: 0;
        animation: pagenameAnim 1s forwards 1.3s;
    }

    @include breakpoint($m) {

        h1 {
            position: absolute;
            right: 50%;
            top: 50%;
            text-align: right;
            max-width: 360px;
            transform: translate3d(0, -300px, 0);
        }

        .textarea {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: 180px;
            text-align: left;
        }
    }
}


.particles-js {
    width: 40%;
    min-width: 400px;
    height: 50vh;
    position: relative;
    transform-style: preserve-3d;

    .logo {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 180px;
        transform: translate3d(-50%, -50%, 0);
        animation: logoEntrance 1s forwards;
    }

    .container {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        .frame {
            position: absolute;
            width: 340px;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);

            img {
                max-width: 100%;
            }
        }
    }

    .scene {
        height: 100%;
        position: relative;
    }

    @include breakpoint($m) {
        height: 100vh;
        width: 800px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes logoEntrance {
    0% {
        opacity: 0;
        transform: translate3d(-50%, -50%, 0) scale(.8);
    }

    100% {
        opacity: 1;
        transform: translate3d(-50%, -50%, 0) scale(1);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 30%, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes pagenameAnim {
    0% {
        color: $color-1;
        transform: translate3d(0, -100px, 0) rotate(90deg);
    }

    50% {
        color: $color-1;
        transform: translate3d(0, 0, 0) rotate(90deg);
        background: $color-1;
    }

    100% {
        opacity: 1;
        background: $color-1;
    }
}