// Colours
$color-1: #111111;
$color-2: #333333;
$color-3: #585862;
$color-4: #7b7b85;

$accent-1: #ed1e79;
$accent-2: #f15a24;

$white: #fff;
$black: #000;

// Typography
$body-font: 'Asap', sans-serif;
$heading-font: 'Asap', sans-serif;
$line-height: 1.8;

// Modular Scale
$modularscale: (
  base: 12px,
  ratio: 1.5,

  400px: (
    ratio: 1.2,
  ),
  900px: (
    base: 14px,
    ratio: 1.3,
  ),
  1200px: (
    base: 14px,
    ratio: 1.4,
  ),
);

// Screen Sizes
$xs: 480px;
$s: 839px;
$m: 1024px;
$l: 1280px;
$xl: 1690px;
