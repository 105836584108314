h1,
h2,
h3,
h4 {
    margin-bottom: 1rem;
    font-family: $heading-font;
    // text-transform: uppercase;
    line-height: 1;
}

h1 {
    @include ms-respond(font-size, 5);
    font-weight: 700;
    line-height: .9;

    span {
        color: $accent-1;
        font-weight: 700;
    }
}

h2 {
    @include ms-respond(font-size, 2);
    font-weight: 700;
    letter-spacing: .05em;

}

h3 {
    @include ms-respond(font-size, 1);
    padding: 10px;
    color: $white;
    background: $color-1;
    font-weight: 700;
    display: inline-block;
    border-radius: 5px
}

h4 {
    @include ms-respond(font-size, 1);
    font-weight: 700;
}
