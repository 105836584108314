.transition-fade {
    transition: .4s;
    opacity: 1;
}

html {
    &.is-animating {
        .transition-fade {
            opacity: 0;
        }
    }
}
