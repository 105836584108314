body {
  @include ms-respond(font-size, 0);
  max-width: 800px;
  margin: 0 auto;
  font-family: $body-font;
  line-height: $line-height;
  color: $color-2;
  font-weight: 300;
}

html {
  width: 100vw;
  scrollbar-width: none;

  ::-webkit-scrollbar {
      width: 0;
  }
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
  display: none;
  opacity: 0;
  transition: ease .3s;
}

.page-name {
  display: none;
  position: absolute;
  top: 120px;
  left: 0;
  background: $color-1;
  color: $white;
  padding: 5px 10px;
  transform: rotate(90deg);
  width: 150px;
  text-align: center;
  font-family: $heading-font;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: .1em;
  border-radius: 5px;

  @include breakpoint($l) {
      display: block;
  }
}

.background-title {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  text-align: center;
  pointer-events: none;
  z-index: -2;
  opacity: .03;
  transform: rotate(90deg);
  transform-origin: center;

  span {
      font-size: 120px;
      text-transform: uppercase;
      font-family: $heading-font;
      font-weight: 700;
      line-height: 1;
      display: block;
  }

  @include breakpoint($l) {
      transform: rotate(0);

      span {
          font-size: 240px;
      }
  }
}